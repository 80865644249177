<template>
  <div class="page-spec-platform-list">
    <spec/>
  </div>
</template>

<script lang="js">
import SpecPlatformListPage from './spec-platform-list';

export default SpecPlatformListPage;
</script>

<style lang="scss">
@import "./spec-platform-list";
</style>
