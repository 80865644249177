import { Component, Vue } from 'vue-property-decorator';
import Spec from '../spec-merchant-list/spec-merchant-list.vue';

@Component({
  components: { Spec }
})
class SpecPlatformListPage extends Vue {
  title = 'spec-platform-list';
}

export default SpecPlatformListPage;
